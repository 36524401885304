<template>
	<div class="dashboard-container">
		<Operate v-if="dialog.refresh" :dialog.sync="dialog.show" :row="dialog.row" :info="dialog.info" @get-list="getList"
		 @refresh="refreshDialog" />
		<DispatchRole v-if="dialog.refresh2" :dialog.sync="dialog.show2" :row="dialog.row" :info="dialog.info" @refresh="refreshDialog" />
		<el-card>
			<el-row type="flex" justify="start" :gutter="0">
                <el-col :span="5">
                    <el-input style="width: 200px" v-model="condition.searchName" class="input searchInput" size="mini" suffix-icon="el-icon-search"
                              placeholder="搜索" @input="searchInput" />
                </el-col>

				<el-col :span="6">
					<span class="text-primary text-pad-right">状态</span>
					<el-select v-model="condition.status" class="input searchInput" clearable size="mini" @change="getList">
						<el-option :value="0" label="启用" />
						<el-option :value="1" label="停用" />
					</el-select>
				</el-col>

			</el-row>
		</el-card>
		<el-card style="margin-top: 20px;">
			<el-row>
				<vxe-toolbar>
					<template v-slot:buttons>
						<el-row type="flex" justify="between">
							<el-col>
								<el-button type="primary" size="mini" @click="openDialog({title:'添加权限',addOr:'add'})">添加权限</el-button>
								<el-button type="warning" size="mini" @click="$refs.table.setAllTreeExpand(true)">展开所有</el-button>
								<el-button type="info" size="mini" @click="$refs.table.clearTreeExpand()">收起所有</el-button>
							</el-col>
							<el-col :span="1">
								<vxe-button @click="getList">刷新</vxe-button>
							</el-col>
						</el-row>
					</template>
				</vxe-toolbar>
				<vxe-table ref="table" border resizable :auto-resize="true" :header-row-style="headerStyle" :row-style="rowStyle"
				 align="center" class="vxe-table-element" height="650" row-id="id" :sort-config="{remote: true}" :filter-config="{remote: true}"
				 :checkbox-config="{reserve: true}" :tree-config="{children: 'sons', expandAll: true,indent:15}" :data="table.list"
				 :loading="loading">
					<vxe-table-column type="seq" width="60" />
					<vxe-table-column field="permissionsName" title="权限名称" tree-node />
					<vxe-table-column field="permissionsCode" title="权限编码" show-overflow="title" />
					<vxe-table-column field="isShow" title="权限类别" />
					<vxe-table-column v-if="$hasBtn('sys:permissions:org:permissions:status')" field="status" title="状态">
						<template v-slot="{ row, rowIndex }">
							<el-switch ref="switch" v-model="row.status" active-color="#13ce66" inactive-color="#ff4949" :active-value="0"
							 :inactive-value="1" @change="(v)=>{changeStatus(v,row)}" />
						</template>
					</vxe-table-column>
					<vxe-table-column field="" title="操作">
						<template v-slot="{ row, rowIndex }">
							<el-dropdown size="small" type="primary">
								<el-button size="small" type="primary">
									操作<i class="el-icon-arrow-down el-icon--right" />
								</el-button>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item icon="el-icon-plus" @click.native="openDialog2({title:'关联企业',addOr:'com'},row)">关联企业</el-dropdown-item>
									<el-dropdown-item icon="el-icon-plus" @click.native="openDialog2({title:'关联角色',addOr:'role'},row)">关联角色</el-dropdown-item>
									<el-dropdown-item icon="el-icon-edit" @click.native="openDialog({title:'修改',addOr:'upd'},row)">修改</el-dropdown-item>
									<el-dropdown-item  icon="el-icon-delete" @click.native="remove({del:'single'},row)">删除</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</template>
					</vxe-table-column>
				</vxe-table>
				<div style="height: 20px" />
			</el-row>
		</el-card>
	</div>
</template>

<script>
	import {
		rowStyle,
		headerStyle
	} from '@/utils/tableStyleJs'
	import Operate from './Operate'
	import DispatchRole from './DispatchRole'
	export default {
		name: 'Index',
		components: {
			Operate,
			DispatchRole
		},
		data() {
			return {
				loading: false,
				condition: {
					searchName: '',
					permissionsCode: '',
					status: '',
					companyId: this.$admin ? '' : this.$companyId,
					type: 1
				},
				table: {
					list: [],
					total: 0,
					currentPage: 1,
					pageSize: 10,
					sortName: '',
					sortBy: ''
				},
				dialog: {
					row: Object,
					info: {},
					refresh: false, // 每次刷新DOM
					refresh2: false, // 每次刷新DOM
					show: false,
					show2: false
				},
				company: []
			}
		},
		created() {
			this.getList()
		},
		methods: {
			rowStyle,
			headerStyle,
			// 打开dialog
			openDialog(info, row) {
				this.dialog.refresh = true
				this.dialog.show = true
				this.dialog.info = info
				this.dialog.row = row
			},
			openDialog2(info, row) {
				this.dialog.refresh2 = true
				this.dialog.show2 = true
				this.dialog.info = info
				this.dialog.row = row
			},
			searchInput(v) {
				this.table.currentPage = 1
				this.getList()
			},
			getList() {
				this.loading = true
				if (this.condition.status === '') {
					this.condition.status = null
				}
				this.$axiosReq('/oauth/server/escort/web/v2.5/appPermissions', null, this.condition, 'get').then(res => {
					this.table.list = res.data;
				}).finally(e => {
					this.loading = false
				})
			},
			changeStatus(v, row) {
				this.$axiosReq('/oauth/server/escort/web/v2.5/appPermissions/status/' + row.id, null, null, 'put').then(res => {
					this.$message.success(res.msg)
				}).finally(() => {
					this.getList()
				})
			},
			remove(info, row) {
				this.$confirm('确认删除吗！！！', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$axiosReq('/oauth/server/escort/web/v2.5/appPermissions/' + row.id, null, null, 'delete').then(res => {
						this.$message.success(res.msg)
						this.getList()
					})
				}).catch(() => {
					this.$message.info('取消了删除！！！')
				})
			},
			handleSizeChange(pageSize) {
				this.table.pageSize = pageSize
				this.table.currentPage = 1
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.table.currentPage = currentPage
				this.getList()
			},
			refreshDialog() {
				this.dialog.refresh = false
				this.dialog.refresh2 = false
			}
		}
	}
</script>

<style scoped>

</style>
