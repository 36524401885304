<template>
	<el-dialog :visible.sync="show" width="40%" :append-to-body="true" :destroy-on-close="true" :close-on-click-modal="false"
	 @close="close">
		<div slot="title" class="header-title">
			<el-row :gutter="5">
				<el-col :span="24">
					<span class="title-name">{{ info.title }}</span>
				</el-col>
			</el-row>
		</div>
		<el-row :gutter="0">
			<el-col :span="24">
				<el-form ref="ruleForm" label-width="150px" class="allForm">
					<el-form-item label="权限名称" :required="true">
						<el-input v-model="form.permissionsName" class="formInput" max-length="20" />
					</el-form-item>
					<el-form-item label="权限编码" :required="true">
						<el-input v-model="form.permissionsCode" class="formInput" :disabled="info.addOr==='upd'" />
					</el-form-item>
					<el-form-item label="ios路由地址" :required="true">
						<el-input v-model="form.appleRoute" class="formInput" />
					</el-form-item>
					<el-form-item label="android路由地址" :required="true">
						<el-input v-model="form.androidRoute" class="formInput" />
					</el-form-item>
					<el-form-item label="图标" :required="true">
						<el-input v-model="form.icon" class="formInput" />
					</el-form-item>
					<!-- <el-form-item label="类型" :required="true">
            <el-select v-model="form.type" class="formInput w-100">
              <el-option v-for="(v,i) in menuLevel" :key="i" :value="v.dictCode" :label="v.dictName" />
            </el-select>
          </el-form-item> -->
					<el-form-item label="父菜单" :required="true">
						<el-cascader v-model="form.parentId" :options="permission" filterable clearable class="formInput w-100" :props="{lazy:false,value:'id',children:'sons',label:'permissionsName',checkStrictly:true,emitPath:false}" />
					</el-form-item>
					<el-form-item label="排序" :required="true">
						<el-input v-model="form.sort" class="formInput" max-length="20" />
					</el-form-item>
					<el-form-item label="是否展示" :required="true">
						<el-select v-model="form.isShow" class="formInput w-100">
							<el-option :value="0" label="否" />
							<el-option :value="1" label="是" />
						</el-select>
					</el-form-item>
				</el-form>
			</el-col>
		</el-row>
		<div slot="footer" class="dialog-footer" style="text-align: center;">
			<el-button type="primary" @click="commit">提交</el-button>
			<el-button type="info" @click="close">取消</el-button>
		</div>
	</el-dialog>
</template>

<script>
	import {
		getDictParentCode,
		permissions
	} from '@/api'
	export default {
		name: 'Operate',
		props: {
			// 打开dialog的
			dialog: {
				type: Boolean, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: false // 这样可以指定默认的值
			},
			info: {
				type: Object, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: () => {} // 这样可以指定默认的值
			},
			row: {
				type: Object, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: () => {} // 这样可以指定默认的值
			},
			company: {
				type: Object, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: () => {} // 这样可以指定默认的值
			}
		},
		data() {
			return {
				show: this.dialog,
				form: {
					permissionsName: '',
					permissionsCode: '',
					appleRoute: '',
					androidRoute: '',
					icon: '',
					parentId: null,
					sort: '',
					isShow: 1
				},
				menuLevel: [],
				permission: []
			}
		},
		created() {
			// this.getDictParentCode()
			this.getPermission()
		},
		mounted() {
			this.upd()
		},
		methods: {
			upd() {
				if (this.info.addOr === 'upd') {
					this.$axiosReq('/oauth/server/escort/web/v2.5/appPermissions/' + this.row.id, null, null, 'get').then(res => {
						this.form = res.data
						this.form.companyNames = null
						this.form.roleNames = null
						this.form.parentId = res.data.parentId
					})
				}
			},
			getDictParentCode() {
				this.$axiosReq(getDictParentCode + 'MENU_LEVEL', null, null, 'get').then(res => {
					this.menuLevel = res.data
				})
			},
			getPermission() {
				this.$axiosReq('/oauth/server/escort/web/v2.5/appPermissions', null, {
					search: '',
					statu: 0,
					type: 1,
					companyId: this.$admin ? null : this.$companyId
				}, 'get').then(res => {
					this.permission = res.data
				})
			},
			// 提交添加
			commit() {
				if (this.$textNull(this.form.permissionsName)) {
					this.$message.error('请填写权限名称！')
					return false;
				} else if (this.$textNull(this.form.permissionsCode)) {
					this.$message.error('请填写权限编码！')
					return false;
				}
				if (!this.form.appleRoute) {
					this.$message.error('请填写ios路由地址！')
					return false;
				}
				if (!this.form.androidRoute) {
					this.$message.error('请填写android路由地址！')
					return false;
				}
				if (!this.form.icon) {
					this.$message.error('请填写图标！')
					return false;
				}
				if (this.info.addOr === 'add') {
					this.$axiosReq('/oauth/server/escort/web/v2.5/appPermissions', null, this.form, 'post').then(res => {
						this.$emit('get-list')
						this.$message.success(res.msg)
						this.close()
					})
				} else {
					this.$axiosReq('/oauth/server/escort/web/v2.5/appPermissions/' + this.row.id, null, this.form, 'put').then(res => {
						this.$emit('get-list')
						this.$message.success(res.msg)
						this.close()
					})
				}
			},
			// 关闭模态框
			close() {
				this.show = false
				this.$emit('refresh')
			}
		}
	}
</script>

<style scoped>

</style>
