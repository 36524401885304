<template>
  <el-dialog
    :visible.sync="show"
    width="30%"
    :append-to-body="true"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    @close="close"
  >
    <div slot="title" class="header-title">
      <el-row :gutter="5">
        <el-col :span="24">
          <span class="title-name">{{ info.title }}</span>
        </el-col>
      </el-row>
    </div>
    <el-row>
      <el-col :span="24">
        <el-form ref="ruleForm" label-width="110px" class="allForm">
          <el-form-item v-if="info.addOr==='role'" label="角色名称" :required="true">
            <el-select v-model="form.roleIds" filterable multiple class="formInput w-100">
              <el-option v-for="(v,i) in roleList" :key="v.id" :value="v.id" :label="v.roleName" />
            </el-select>
          </el-form-item>
          <el-form-item v-else label="公司名称" :required="true">
            <el-select v-model="companyIds" filterable multiple class="formInput w-100">
              <el-option v-for="(v,i) in companyList" :key="v.id" :value="v.id" :label="v.companyName" />
            </el-select>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <div slot="footer" class="dialog-footer" style="text-align: center;">
      <el-button type="primary" @click="commit">提交</el-button>
      <el-button type="info" @click="close">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { role, permissionsRole, permissionsCompany, company, permissions } from '@/api'
export default {
  name: 'DispatchMenu',
  props: {
    // 打开dialog的
    dialog: {
      type: Boolean, // 指定传入的类型
      // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
      default: false // 这样可以指定默认的值
    },
    info: {
      type: Object, // 指定传入的类型
      // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
      default: () => {} // 这样可以指定默认的值
    },
    row: {
      type: Object, // 指定传入的类型
      // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
      default: () => {} // 这样可以指定默认的值
    },
    company: {
      type: Object, // 指定传入的类型
      // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
      default: () => {} // 这样可以指定默认的值
    }
  },
  data() {
    return {
      show: this.dialog,
      roleList: [],
      companyList: [],
      form: {
        roleIds: []
      },
      companyIds: []
    }
  },
  created() {
    if (this.info.addOr === 'role') {
      this.getRole()
    } else {
      this.getCompany()
    }
    this.upd()
  },
  methods: {
    upd() {
      this.$axiosReq(permissions + this.row.id, null, null, 'get').then(res => {
        for (const i of res.data.companyNames) {
          this.companyIds.push(i.companyId)
        }
        for (const i of res.data.roleNames) {
          this.form.roleIds.push(i.roleId)
        }
      })
    },
    getRole() {
      this.$axiosReq(role, null, {
        pageSize: 10000
      }, 'get').then(res => {
        this.roleList = res.data.content
      })
    },
    getCompany() {
      this.$axiosReq(company, null, {
        pageSize: 10000
      }, 'get').then(res => {
        this.companyList = res.data.content
      })
    },
    // 提交添加
    commit() {
      if (this.info.addOr === 'role') {
        if (this.form.roleIds.length === 0) {
          this.$message.error('请选择角色！')
          return
        }
        this.$axiosReq('/oauth/server/escort/web/v2.5/appPermissions/role/' + this.row.id, this.form.roleIds, null, 'put').then(res => {
          this.$message.success(res.msg)
          this.close()
        })
      } else {
        if (this.companyIds.length === 0) {
          this.$message.error('请选择公司！')
          return
        }
        this.$axiosReq('/oauth/server/escort/web/v2.5/appPermissions/company/' + this.row.id, this.companyIds, null, 'put').then(res => {
          this.$message.success(res.msg)
          this.close()
        })
      }
    },
    // 关闭模态框
    close() {
      this.show = false
      this.$emit('refresh')
    }
  }
}
</script>

<style scoped>

</style>
